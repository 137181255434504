<template>
  <div class="page__container">
    <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
      <v-card v-if="!submitted" flat>
        <v-card-title>
          <v-row align="center" class="mb-n2">
            <v-col cols="auto">
              <h2>{{ productName }}</h2>
            </v-col>
            <v-col cols="auto">
              <partner-logos />
            </v-col>
          </v-row>
          <v-progress-linear color="transparent" class="gradient-diagonal--primary" height="8px" />
          <v-row>
            <v-col>
              <h3 class="semi-bold">{{ $t('onboarding.sendRequest') }}</h3>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <error-alert :value="failed" />

          <p class="mt-4">{{ $t('onboarding.fillRequest', { productName: productName }) }}</p>
          <v-row>
            <v-col cols="12">
              <app-text-field
                id="onboarding-request__company"
                v-model="formData.company"
                label="company"
                required
              />
            </v-col>
          </v-row>
          <p>{{ $t('contactPerson') }}</p>
          <v-row class="mt-n2">
            <v-col cols="12" md="2">
              <app-salutation-select
                id="onboarding-request__salutation"
                v-model="formData.salutation"
              />
            </v-col>
            <v-col cols="12" md="5">
              <app-text-field
                id="onboarding-request__first-name"
                v-model="formData.firstName"
                label="firstName"
              />
            </v-col>
            <v-col cols="12" md="5">
              <app-text-field
                id="onboarding-request__last-name"
                v-model="formData.lastName"
                label="lastName"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <app-text-field
                id="onboarding-request__trade-name"
                v-model="formData.tradeName"
                label="tradeName"
              />
            </v-col>
            <v-col v-if="dataNotBlacklisted(fields.FOUNDING_DATE)" cols="12" md="4">
              <app-date-picker
                id="onboarding-request__founding-date"
                v-model="formData.foundingDate"
                :custom-error-messages="{ min_past_months: $t('onboarding.foundingDateNotValid') }"
                :max="maxFoundingDate"
                :rules="getRules(fields.FOUNDING_DATE, 'required|min_past_months:6')"
                append-icon
                label="onboarding.foundingDate"
                placeholder="dateHint"
              />
            </v-col>
            <v-col
              v-if="dataNotBlacklisted(fields.TURNOVER)"
              cols="12"
              :md="alignTradenameAndTurnover ? 6 : 4"
            >
              <app-number-field
                id="onboarding-request__turnover"
                v-model="formData.turnover"
                :rules="getRules(fields.TURNOVER, '')"
                label="annualTurnover"
                append-icon="$iconEuro"
              />
            </v-col>
            <v-col v-if="dataNotBlacklisted(fields.ONLINE_TURNOVER_RATE)" cols="12" md="6">
              <app-text-field
                id="onboarding-request__online-turnover-rate"
                v-model="formData.onlineTurnoverRate"
                :custom-error-messages="{
                  max_value: $t('onboarding.maxOnlineTurnoverRateInvalid', { max: 40 })
                }"
                rules="required|percent|max_value:40"
                label="onboarding.annualOnlineTurnoverRate"
                max="100"
                min="0"
                step="0.1"
                type="number"
              />
            </v-col>
            <v-col v-if="dataNotBlacklisted(fields.FACTORABLE_TURNOVER)" cols="12" md="6">
              <app-number-field
                id="onboarding-request__factorable-turnover"
                ref="factorableTurnover"
                :value="factorableTurnover"
                :rules="getRules(fields.FACTORABLE_TURNOVER, 'factorable_turnover')"
                append-icon="$iconEuro"
                disabled
                label="onboarding.factorableAnnualTurnover"
              />
            </v-col>
            <v-col
              v-if="
                dataNotBlacklisted(fields.CREDITOR_LIMIT) &&
                selectedProduct.onboarding.useCalculatedCreditorLimit
              "
              cols="12"
              md="6"
            >
              <app-number-field
                id="onboarding-request__creditor-limit"
                :value="creditorLimit"
                append-icon="$iconEuro"
                disabled
                label="onboarding.determinedCreditorLimit"
              />
            </v-col>
          </v-row>
          <v-row v-if="dataNotBlacklisted(fields.VOB)">
            <v-col cols="12">
              <app-radio-group
                id="onboarding-request__vob"
                v-model="formData.vob"
                :items="vobItems"
                name="onboarding.vob"
              >
                <template #label>
                  {{ $t('onboarding.vobLabel') }}
                  <app-tooltip inline>
                    <template #text>
                      <span v-html="$t('onboarding.vobInfoHtml')"></span>
                    </template>
                  </app-tooltip>
                </template>
              </app-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <app-text-field
                id="onboarding-request__street-and-house"
                v-model="formData.streetAndHouse"
                label="streetAndHouse"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <app-text-field
                id="onboarding-request__zip"
                v-model="formData.zip"
                label="zip"
                rules="required|numeric|length: 5"
              />
            </v-col>
            <v-col cols="12" md="9">
              <app-text-field
                id="onboarding-request__city"
                v-model="formData.city"
                label="city"
                required
              />
            </v-col>
          </v-row>

          <div class="mx-n12 mt-12 px-12 py-8 grey-background">
            <p>{{ $t('onboarding.intermediaryInfo') }}</p>
            <v-row>
              <v-col cols="12" md="2">
                <app-salutation-select
                  id="onboarding-request__intermediary-salutation"
                  v-model="formData.intermediary.salutation"
                />
              </v-col>
              <v-col cols="12" md="5">
                <app-text-field
                  id="onboarding-request__intermediary-first-name"
                  v-model="formData.intermediary.firstName"
                  label="firstName"
                  required
                  background-color="white"
                />
              </v-col>
              <v-col cols="12" md="5">
                <app-text-field
                  id="onboarding-request__intermediary-last-name"
                  v-model="formData.intermediary.lastName"
                  label="lastName"
                  required
                  background-color="white"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <app-text-field
                  id="onboarding-request__intermediary-phone"
                  v-model="formData.intermediary.phone"
                  label="phone"
                  rules="required|phone"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12" md="6">
                <app-text-field
                  id="onboarding-request__intermediary-email"
                  v-model="formData.intermediary.email"
                  label="email"
                  rules="required|email"
                  background-color="white"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <app-textarea
                  id="onboarding-request__notes"
                  v-model="formData.notes"
                  label="onboarding.notes"
                  rows="3"
                  background-color="white"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <app-checkbox
                  id="onboarding-request__privacy-policy-confirmation"
                  v-model="formData.intermediary.privacyPolicyAccepted"
                  label="onboarding.privacyPolicyConfirmation"
                  required
                >
                  <i18n path="onboarding.privacyPolicyAccepted">
                    <template #link>
                      <a
                        :href="privacyPolicyUrl"
                        class="privacy-policy-link"
                        target="_blank"
                        @click.stop
                      >
                        {{ privacyPolicyUrl }}
                      </a>
                    </template>
                  </i18n>
                </app-checkbox>
              </v-col>
            </v-row>
          </div>

          <div class="body-2 mt-4">*{{ $t('onboarding.requiredFields') }}</div>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center" align="stretch">
            <v-col cols="6" md="3">
              <app-btn
                id="onboarding-request__btn-cancel"
                block
                outlined
                title="navigation.cancel"
                :disabled="loading"
                @click="$router.push({ name: routeName.ONBOARDING })"
              />
            </v-col>
            <v-col cols="6" md="3">
              <app-btn
                id="onboarding-request__btn-submit"
                :loading="loading"
                type="submit"
                block
                title="onboarding.submitRequest"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <success-card v-else />
    </ValidationObserver>
  </div>
</template>

<script>
import AppSalutationSelect from '@/shared/components/AppSalutationSelect';
import ErrorAlert from '@/shared/CreateCreditor/ErrorAlert';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import PartnerLogos from '@/shared/components/PartnerLogos';
import SuccessCard from '@/onboarding/components/SuccessCard';
import calculateAnnualServiceFee from '@/onboarding/helper/calculateAnnualServiceFee';
import calculateCreditorLimitFromTurnover from '@/onboarding/helper/calculateCreditorLimitFromTurnover';
import calculateFactorableAnnualTurnover from '@/onboarding/helper/calculateFactorableAnnualTurnover';
import { NAMESPACE } from '@/onboarding/store';
import { PRIVACY_POLICY_FINTECRITY } from '@/statics/customerServiceInformation';
import { formatDateToTimestamp, formatIsoDateOfMonthsFromToday } from '@/helper/filter/formatDate';
import { mapActions } from 'vuex';

export default {
  name: 'Request',

  mixins: [OnboardingProductMixin],

  components: {
    ErrorAlert,
    SuccessCard,
    PartnerLogos,
    AppSalutationSelect
  },

  data: () => ({
    formData: {
      company: '',
      salutation: '',
      firstName: '',
      lastName: '',
      tradeName: '',
      turnover: '',
      onlineTurnoverRate: '',
      foundingDate: '',
      serviceFee: '',
      vob: '',
      streetAndHouse: '',
      zip: '',
      city: '',
      intermediary: {
        salutation: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        privacyPolicyAccepted: false
      },
      notes: ''
    },
    loading: false,
    submitted: false
  }),

  computed: {
    factorableTurnover() {
      return calculateFactorableAnnualTurnover(
        this.formData.turnover,
        this.formData.onlineTurnoverRate
      );
    },
    creditorLimit() {
      return calculateCreditorLimitFromTurnover(
        this.factorableTurnover,
        this.product.onboarding.maxCreditorLimitInCent
      );
    },
    annualServiceFee() {
      return calculateAnnualServiceFee(this.factorableTurnover, this.formData.serviceFee);
    },
    maxFoundingDate() {
      return formatIsoDateOfMonthsFromToday(-6);
    },
    alignTradenameAndTurnover() {
      return this.dataNotBlacklisted(this.fields.FOUNDING_DATE);
    },
    vobItems() {
      return [
        {
          label: this.$t('yes'),
          value: 1,
          id: 'creditor-registration__vob--yes'
        },
        {
          label: this.$t('no'),
          value: 0,
          id: 'creditor-registration__vob--no'
        }
      ];
    },
    privacyPolicyUrl() {
      return PRIVACY_POLICY_FINTECRITY;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['submitRequest', 'fetchDefaultServiceFeeMatrix']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return this.$vuetify.goTo(document.querySelector('.input--has-error'), {
          duration: 500,
          offset: 0,
          easing: 'easeInOutCubic',
          container: '.app-container'
        });
      }

      this.loading = true;
      const payload = {
        product: this.productType,
        form: {
          name: this.formData.company,
          tradeName: this.formData.tradeName,
          annualTurnoverCross: this.formData.turnover ?? '',
          ...(this.formData.onlineTurnoverRate
            ? {
                onlineTurnoverRate: parseFloat(this.formData.onlineTurnoverRate)
              }
            : {}),
          factorableAnnualTurnoverCross: this.dataNotBlacklisted(this.fields.FACTORABLE_TURNOVER)
            ? this.factorableTurnover
            : '',
          creditorLimit:
            this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT) &&
            this.selectedProduct.onboarding.useCalculatedCreditorLimit
              ? this.creditorLimit
              : '' /* limit not required or later set by admin in creditor-create action */,
          creditorServiceFee: {
            serviceFeeAnnual: this.isFactoring ? this.annualServiceFee : ''
          },
          ...(this.formData.vob !== ''
            ? {
                vob: !!this.formData.vob
              }
            : {}),
          foundingDate: this.dataNotBlacklisted(this.fields.FOUNDING_DATE)
            ? formatDateToTimestamp(this.formData.foundingDate)
            : '',
          street: this.formData.streetAndHouse,
          zip: this.formData.zip,
          city: this.formData.city,
          contactPerson: {
            salutation: this.formData.salutation,
            forename: this.formData.firstName,
            surname: this.formData.lastName
          },
          intermediary: {
            salutation: this.formData.intermediary.salutation,
            forename: this.formData.intermediary.firstName,
            surname: this.formData.intermediary.lastName,
            telephoneNumber: this.formData.intermediary.phone,
            email: this.formData.intermediary.email,
            privacyPolicyAccepted: this.formData.intermediary.privacyPolicyAccepted
          },
          notes: this.formData.notes
        }
      };

      const { error } = await this.submitRequest(payload);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  },

  watch: {
    '$route.query.token': {
      immediate: true,
      async handler(token) {
        if (!token) return;

        const { data } = await this.$http.get(`/sso/read`, { params: { token } });

        if (data.error) return;

        this.formData = {
          ...this.formData,
          company: data.creditor.name,
          firstName: data.creditor.contact_person.forename,
          lastName: data.creditor.contact_person.surname,
          streetAndHouse: `${data.creditor.address.street} ${data.creditor.address.house}`.trim(),
          city: data.creditor.address.city,
          zip: data.creditor.address.zip,
          intermediary: {
            ...this.formData.intermediary,
            firstName: data.consultant.forename,
            lastName: data.consultant.surname,
            phone: data.consultant.telephone,
            email: data.consultant.email
          }
        };
      }
    },

    async factorableTurnover() {
      await this.$nextTick(); // wait for internal field value to update
      await this.$refs.factorableTurnover?.validateField();
    }
  },

  async created() {
    if (!this.isFactoring) {
      return;
    }

    const { data } = await this.fetchDefaultServiceFeeMatrix(this.productType);
    if (!data) {
      return;
    }

    this.formData.serviceFee = data.purchasedFee;
  }
};
</script>

<style scoped lang="scss">
.privacy-policy-link {
  display: inline-block;
  word-break: break-word;
}
</style>
