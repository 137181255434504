export const calculateFactorableAnnualTurnover = (turnoverInCent, onlineTurnoverPercent) => {
  if (
    [turnoverInCent, onlineTurnoverPercent].some(
      (value) => [null, undefined, ''].includes(value) || isNaN(value)
    )
  ) {
    return '';
  }

  return turnoverInCent * ((100 - onlineTurnoverPercent) / 100);
};

export default calculateFactorableAnnualTurnover;
