<template>
  <v-card flat max-width="700">
    <v-card-title class="flex-column">
      <img
        :src="require('@/onboarding/assets/check.svg')"
        :alt="$t(successMessages.title)"
        width="110"
      />
      <h1 class="mt-2">{{ $t(successMessages.title) }}</h1>
    </v-card-title>

    <v-card-text class="text-center">
      <h3>
        {{ $t(successMessages.text) }}
      </h3>
    </v-card-text>

    <v-card-actions class="flex-column">
      <v-col cols="12" md="6">
        <app-btn
          id="onboarding__btn-success"
          block
          title="navigation.ok"
          @click="$router.push({ name: routeName.ONBOARDING })"
        />
      </v-col>
      <v-col cols="12" class="text-h5 font-weight-light text-center">
        {{ $t('onboarding.finishedRegistration') }}
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SuccessCard',

  computed: {
    successMessages() {
      return this.$route.name === this.routeName.ONBOARDING_REGISTRATION
        ? {
            text: 'onboarding.successTextRegistration',
            title: 'onboarding.successTitleRegistration'
          }
        : {
            text: 'onboarding.successTextRequest',
            title: 'onboarding.successTitleRequest'
          };
    }
  }
};
</script>

<style scoped>
.v-card {
  margin: 0 auto;
}
</style>
