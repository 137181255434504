import { render, staticRenderFns } from "./SuccessCard.vue?vue&type=template&id=c5d4035a&scoped=true&"
import script from "./SuccessCard.vue?vue&type=script&lang=js&"
export * from "./SuccessCard.vue?vue&type=script&lang=js&"
import style0 from "./SuccessCard.vue?vue&type=style&index=0&id=c5d4035a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d4035a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VCol})
