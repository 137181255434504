const UPLOADED_VOLUME_PERCENTAGE = 40; // percentage for expected uploaded invoice volume of annual turnover

export const calculateAnnualServiceFee = (turnover, serviceFee) => {
  if (!turnover || !serviceFee) {
    return '';
  }

  return Math.round(turnover * (UPLOADED_VOLUME_PERCENTAGE / 100) * (serviceFee / 100));
};

export default calculateAnnualServiceFee;
