<template>
  <v-row v-if="value" dense class="error--text semi-bold subtitle-1">
    <v-col cols="auto"><icon-alert /></v-col>
    <v-col>{{ $t('onboarding.correctMistakes') }}</v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ErrorAlert',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
